import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-background text-text py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
        <div className="flex-grow text-center md:text-left px-4 md:px-[151.2px]">
          <p className="text-lg">&copy; 2024 Medal Team. All rights reserved.</p>
        </div>
        <div className="flex-grow text-center">
          <p className="flex justify-center space-x-4 text-lg">
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-text hover:text-primary transition-colors duration-300 flex items-center space-x-2"
            >
              <i className="fab fa-github text-lg"></i>
              <span>GitHub</span>
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-text hover:text-primary transition-colors duration-300 flex items-center space-x-2"
            >
              <i className="fab fa-linkedin text-lg"></i>
              <span>LinkedIn</span>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-text hover:text-primary transition-colors duration-300 flex items-center space-x-2"
            >
              <i className="fab fa-twitter text-lg"></i>
              <span>Twitter</span>
            </a>
          </p>
        </div>
        <div className="flex-grow text-center md:text-right px-4 md:px-[151.2px]">
          <p className="text-lg">
            <a href="/terms" className="hover:text-primary transition-colors duration-300">Terms of Service</a>
            <span className="mx-2">|</span>
            <a href="/privacy" className="hover:text-primary transition-colors duration-300">Privacy Policy</a>
            <span className="mx-2">|</span>
            <a href="/website" className="hover:text-primary transition-colors duration-300">Website</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
