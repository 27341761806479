import React from 'react';
import { FaCheckCircle, FaFacebook, FaDiscord, FaGithub, FaInstagram, } from 'react-icons/fa';

const founders = [
  {
    name: '،Virus.',
    role: 'Full Stack Developer',
    bio: 'Im Mohamad A FullStack Developer From Egypt Iam The Fonder Of MaxBot & Medal Team, Working With Bally Server',
    img: 'https://cdn.discordapp.com/avatars/301340095653347329/522ca1e77663b99e20b38ed2b81f0aa7.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      github: 'https://github.com/Ziw1',
      facebook: 'https://www.facebook.com/5ih01',
      instagram: 'https://www.instagram.com/5ih01',
      discord: 'https://discord.com/users/301340095653347329',
    },
  },
  {
    name: 'Winget',
    role: 'Backend Developer',
    bio: 'A BackEnd Developer Works With Medal Team',
    img: 'https://cdn.discordapp.com/avatars/967380255666995260/9ae89d42897c8f70a8570c1bdd1a754b.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      github: 'https://github.com/AkramStation',
      instagram: 'https://www.facebook.com/founder2',
      discord: 'https://discord.com/users/967380255666995260',
    },
  },
  {
    name: 'Sir.Witty',
    role: 'Backend & Frontend Developer',
    bio: 'Hello! I am your friendly web wizard who started dancing with JavaScript in the old days, self-taught. i use React.js , Tailwind CSS, JS , CSS',
    img: 'https://images-ext-1.discordapp.net/external/qYWe1XIrQGxhkTvAKCFwQKbXXVecBqQuwDu9QAKDwAk/%3Fsize%3D1024/https/cdn.discordapp.com/avatars/1091118468155314306/91997c323ec129b1a88eff7293c17771.webp?format=webp', 
    socialLinks: {
      github: 'https://github.com/SrWitty',
      facebook: 'https://www.facebook.com/iim7md11/',
      discord: 'https://discord.com/users/1091118468155314306',
    },
  },
];

const developers = [
  {
    name: 'Rio',
    role: 'BackEnd Deveolper',
    bio: 'A BackEnd Developer Works With Medal Team & Max Bot',
    img: 'https://cdn.discordapp.com/avatars/571658286261207040/2ff044088f08f80c27530878584186fe.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      instagram: 'https://www.instagram.com/8.r5io',
      discord: 'https://discord.com/users/571658286261207040',
    },
  },
  {
    name: 'Mr.Kasper',
    role: 'FullStack Developer',
    bio: 'Ahmed Falih is a full-stack developer with expertise in backend and frontend technologies.',
    img: 'https://cdn.discordapp.com/avatars/508449321176268801/0740d4e53ae477d58647f98dd989f9e4.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      github: 'https://github.com/z7pz',
      instagram: 'https://www.facebook.com/profile.php?id=100041550305428',
      discord: 'https://discord.com/users/508449321176268801',
    },
  },
  {
    name: 'Etherl',
    role: 'Software Engineer',
    bio: 'Im Ahmed A Software Engineer From Egypt, Language I Work Whis It JavaScript & Python & Rust',
    img: 'https://cdn.discordapp.com/avatars/603630277473992725/768b6be127cb984f746fe95032c3bcbc.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      github: 'https://github.com/Etherll',
      discord: 'https://discord.gg/teamcord-1057301842045968444',
    },
  },
  {
    name: 'Kqatso',
    role: 'Designer',
    bio: 'A Designer Works With Medal Team',
    img: 'https://cdn.discordapp.com/avatars/301340095653347329/522ca1e77663b99e20b38ed2b81f0aa7.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      github: 'https://github.com/kqatso',
      instagram: 'https://www.instagram.com/kqatso',
      discord: 'https://discord.com/users/473478635060461571',
    },
  },
  {
    name: 'Venom',
    role: 'Developer',
    bio: 'A Developer Works With Medal Team',
    img: 'https://cdn.discordapp.com/avatars/930924322023940137/cd32d8a5f025d1df8775d5b923e90728.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      github: 'https://github.com/Venom01s',
      instagram: 'https://www.instagram.com/eyadhady12/',
      discord: 'https://discord.com/users/930924322023940137',
    },
  },
  {
    name: 'Skl2r',
    role: 'Designer',
    bio: 'A Designer Works With Medal Team',
    img: 'https://cdn.discordapp.com/avatars/1052211556735266856/c33075c775800f97c00b865ddd23b2a5.png?size=1024&format=webp&quality=lossless&width=0&height=256', 
    socialLinks: {
      instagram: 'https://www.instagram.com/xosaad',
      discord: 'https://discord.gg/users/1052211556735266856',
    },
  },
];

const Team = () => {
  return (
    <div className="p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 max-w-6xl mt-14 mx-auto">
      <h1 className="text-4xl font-bold text-center mb-8">
        Meet the Team
      </h1>
      
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-center mb-6">Founders</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-8">
          {founders.map((member, index) => (
            <div 
              key={index} 
              className="bg-white dark:bg-[#1E1E1E] p-4 sm:p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <img 
                src={member.img} 
                alt={member.name} 
                className="w-24 h-24 sm:w-32 sm:h-32 rounded-full mx-auto mb-4 border-4 border-primary dark:border-accent"
              />
              <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-gray-900 dark:text-gray-100 flex items-center justify-center">
                {member.name}
                <FaCheckCircle className="text-green-500 ml-2" />
              </h2>
              <p className="text-base sm:text-lg font-medium mb-1 text-gray-800 dark:text-gray-200">
                {member.role}
              </p>
              <p className="text-sm sm:text-base text-gray-700 dark:text-gray-300 mb-4">
                {member.bio}
              </p>
              <div className="flex justify-center space-x-3 sm:space-x-4">
                {member.socialLinks.github && (
                  <a 
                    href={member.socialLinks.github} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-gray-600 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    <FaGithub className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
                {member.socialLinks.facebook && (
                  <a 
                    href={member.socialLinks.facebook} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    <FaFacebook className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
                {member.socialLinks.instagram && (
                  <a 
                    href={member.socialLinks.instagram} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"
                  >
                    <FaInstagram className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
                {member.socialLinks.discord && (
                  <a 
                    href={member.socialLinks.discord} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"
                  >
                    <FaDiscord className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h2 className="text-3xl font-bold text-center mb-6">Developers</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 md:gap-8">
          {developers.map((member, index) => (
            <div 
              key={index} 
              className="bg-white dark:bg-[#1E1E1E] p-4 sm:p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <img 
                src={member.img} 
                alt={member.name} 
                className="w-24 h-24 sm:w-32 sm:h-32 rounded-full mx-auto mb-4 border-4 border-primary dark:border-accent"
              />
              <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-gray-900 dark:text-gray-100 flex items-center justify-center">
                {member.name}
                <FaCheckCircle className="text-green-500 ml-2" />
              </h2>
              <p className="text-base sm:text-lg font-medium mb-1 text-gray-800 dark:text-gray-200">
                {member.role}
              </p>
              <p className="text-sm sm:text-base text-gray-700 dark:text-gray-300 mb-4">
                {member.bio}
              </p>
              <div className="flex justify-center space-x-3 sm:space-x-4">
                {member.socialLinks.github && (
                  <a 
                    href={member.socialLinks.github} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-gray-600 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    <FaGithub className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
                {member.socialLinks.facebook && (
                  <a 
                    href={member.socialLinks.facebook} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    <FaFacebook className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
                {member.socialLinks.instagram && (
                  <a 
                    href={member.socialLinks.instagram} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    <FaInstagram className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
                {member.socialLinks.discord && (
                  <a 
                    href={member.socialLinks.discord} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"
                  >
                    <FaDiscord className="text-2xl sm:text-3xl" /> {/* Updated size */}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Team;