import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faBriefcase, faUsers, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-background text-text shadow-xl fixed top-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center p-2 sm:p-3 md:p-2">
        <Link to="/" className="text-xl sm:text-2xl md:text-3xl font-extrabold hover:text-primary transition-colors duration-300">
          Medal Team
        </Link>
        <button className="text-text text-2xl sm:text-3xl md:hidden" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <ul className={`md:flex md:space-x-6 text-sm sm:text-base md:text-lg font-medium absolute md:static top-full left-0 w-full md:w-auto bg-background md:bg-transparent transition-all duration-300 ease-in-out ${isOpen ? 'block' : 'hidden md:block'}`}>
          <li>
            <Link to="/" className="flex items-center p-2 hover:text-primary transition-colors duration-300">
              <FontAwesomeIcon icon={faHome} className="mr-1 sm:mr-2 text-base sm:text-lg md:text-xl" /> Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="flex items-center p-2 hover:text-primary transition-colors duration-300">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1 sm:mr-2 text-base sm:text-lg md:text-xl" /> About
            </Link>
          </li>
          <li>
            <Link to="/works" className="flex items-center p-2 hover:text-primary transition-colors duration-300">
              <FontAwesomeIcon icon={faBriefcase} className="mr-1 sm:mr-2 text-base sm:text-lg md:text-xl" /> Works
            </Link>
          </li>
          <li>
            <Link to="/team" className="flex items-center p-2 hover:text-primary transition-colors duration-300">
              <FontAwesomeIcon icon={faUsers} className="mr-1 sm:mr-2 text-base sm:text-lg md:text-xl" /> Team
            </Link>
          </li>
          <li>
            <Link to="/contact" className="flex items-center p-2 hover:text-primary transition-colors duration-300">
              <FontAwesomeIcon icon={faEnvelope} className="mr-1 sm:mr-2 text-base sm:text-lg md:text-xl" /> Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
