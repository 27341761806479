import React from 'react';
import { motion } from 'framer-motion'; 
import { FaCheckCircle } from 'react-icons/fa'; 

const TeamMemberCard = ({ name, role, image }) => {
  
  const cardAnimation = {
    hidden: { scale: 0.95, opacity: 0.8 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.3 } },
  };

  return (
    <motion.div
      className="bg-secondary text-text rounded-lg shadow-lg p-6 text-center hover:shadow-2xl transition-shadow duration-300 border-2 border-primary relative"
      initial="hidden"
      whileHover="visible"
      variants={cardAnimation}
    >
      <img src={image} alt={name} className="w-32 h-32 rounded-full mx-auto mb-6 object-cover" />
      <h4 className="text-2xl font-bold mb-2">{name}</h4>
      <p className="text-primary">{role}</p>
      <FaCheckCircle className="absolute top-2 right-2 text-primary text-xl" />
    </motion.div>
  );
};

export default TeamMemberCard;
