import React from 'react';
import { motion } from 'framer-motion';
import psFlag from '../assets/images/ps.png'; 
import psGif from '../assets/images/Flag_of_Palestine.gif';


const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } },
};

const WebAbout = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <motion.h1
        className="text-4xl font-bold text-center mb-8 text-primary"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        About Website
      </motion.h1>
      <motion.div
        className="flex flex-col items-center md:flex-row md:items-start md:space-x-8"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <img
          src="https://images-ext-1.discordapp.net/external/qYWe1XIrQGxhkTvAKCFwQKbXXVecBqQuwDu9QAKDwAk/%3Fsize%3D1024/https/cdn.discordapp.com/avatars/1091118468155314306/91997c323ec129b1a88eff7293c17771.webp?format=webp"
          alt="M7md Aka Sir_Witty"
          className="w-48 h-48 rounded-full border-4 border-primary dark:border-accent shadow-lg"
        />
        <div>
          <motion.h2
            className="text-3xl font-bold text-primary mb-4"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            M7md Aka Sir_Witty
          </motion.h2>
          <motion.p
            className="text-gray-700 mb-4"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            Hi, I'm M7md Aka Sir_Witty. I am a web developer passionate about creating modern, user-friendly websites. With a background in web development, I specialize in building responsive and visually appealing web applications using the latest technologies.
          </motion.p>
          <motion.p
            className="text-gray-700 mb-4"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            In addition to web development, I am also an advocate for various causes, including supporting Palestine. I believe in using my skills to raise awareness and support important issues.
          </motion.p>
          <motion.p
            className="text-gray-700 mb-4"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            My job involves working on innovative projects that push the boundaries of web technology and design. I am always eager to take on new challenges and contribute to exciting projects.
          </motion.p>
        </div>
      </motion.div>
      <motion.div
        className="mt-12 text-center"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h2 className="text-2xl font-bold mb-4 text-primary">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#007A33] via-[#FFFFFF] to-[#CF0000]">
            Stand With Palestine
          </span>
        </h2>
        <div className="flex flex-col items-center justify-center space-y-6"> 
          <img 
            src={psFlag} 
            alt="Palestinian Flag" 
            className="w-24 h-16 object-cover"  
          />
          <p className="text-gray-700 text-lg">
            We stand with Palestine and support their cause.
          </p>
          <img 
            src={psGif} 
            alt="Palestinian Flag GIF" 
            className="w-64 h-40 object-cover"  
          />
          <p className="text-gray-700 text-lg mt-4">
            This website is developed by M7md Aka Sir_Witty. I am dedicated to creating engaging and impactful web experiences.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default WebAbout;
