import React from 'react';
import { motion } from 'framer-motion';
import { FaDiscord, FaFacebook, FaInstagram } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } },
};

const bounceIn = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8, type: 'spring', stiffness: 300 } },
};

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

   
    const form = e.target;

    
    emailjs.sendForm('your_service_id', 'your_template_id', form, 'your_user_id')
      .then((result) => {
        console.log('Success:', result.text);
        alert('Message sent successfully!');
        form.reset();
      }, (error) => {
        console.error('Error:', error.text);
        alert('Failed to send message. Please try again.');
      });
  };

  return (
    <div className="container mx-auto px-4 py-12 mt-14">
      <motion.h1
        className="text-4xl font-bold text-center mb-8 text-primary"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        Contact Us
      </motion.h1>
      <motion.form
        className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
        onSubmit={handleSubmit}
      >
        <div className="mb-6">
          <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition duration-300"
            placeholder="Your Name"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="phone" className="block text-gray-700 font-semibold mb-2">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition duration-300"
            placeholder="Your Phone Number"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="subject" className="block text-gray-700 font-semibold mb-2">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition duration-300"
            placeholder="Subject"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">Message</label>
          <textarea
            id="message"
            name="message"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition duration-300"
            placeholder="Your Message"
            rows="4"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-primary text-white py-3 rounded-lg hover:bg-primary-dark transition duration-300"
        >
          Send Message
        </button>
      </motion.form>
      <motion.div
        className="mt-12 text-center"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h2 className="text-2xl font-bold mb-4 text-primary">Connect With Us</h2>
        <motion.div
          className="flex justify-center space-x-6"
          initial="hidden"
          animate="visible"
          variants={bounceIn}
        >
          <a href="https://discord.gg/your-invite-code" className="text-primary hover:text-primary-dark transition duration-300" aria-label="Discord">
            <FaDiscord className="text-3xl" />
          </a>
          <a href="https://facebook.com/your-profile" className="text-primary hover:text-primary-dark transition duration-300" aria-label="Facebook">
            <FaFacebook className="text-3xl" />
          </a>
          <a href="https://instagram.com/your-profile" className="text-primary hover:text-primary-dark transition duration-300" aria-label="Instagram">
            <FaInstagram className="text-3xl" />
          </a>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Contact;
