import React from 'react';
import { motion } from 'framer-motion';
import Card from '../components/Card'; 
import discordBotSvg from '../assets/images/maxbot.gif';
import dbDbSvg from '../assets/images/database.gif';

const Works = () => {

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  const handleRequestProjectClick = () => {
    window.open('https://discord.gg/medal-team', '_blank');
  };

  return (
    <section id="projects" className="bg-d6dff8 py-5 mt-16">
      <div className="container mx-auto px-6">
        <motion.h2
          className="text-center text-4xl font-bold mb-4"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          Our Works
        </motion.h2>
        <motion.p
          className="text-gray-700 text-center mb-12"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          Take a look at some of the projects we've worked on. Our portfolio showcases a wide range of skills and expertise. If you have any project ideas or requests, feel free to reach out to us!
        </motion.p>
        <motion.div
          className="flex flex-wrap gap-6 justify-center items-center"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 mb-4 flex justify-center">
            <Card
              title="MaxBot"
              author="MedalTeam"
              description="Welcome to the official website repository for our Discord bot. This website serves as a hub of information, updates, and support for our Discord community. Engage with us and explore the functionalities of MaxBot!"
              discordLink="https://discord.gg/your-discord-link" 
              websiteLink="https://max-bot.xyz/"
              image={discordBotSvg} 
              icon="discord" 
            />
          </div>
          <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 mb-4 flex justify-center">
            <Card
              title="medal.db"
              author="MedalTeam"
              description="A simple and easy database package to make your life easier. Latest version: 1.0.0. Integrate medal.db into your projects with ease. Check out the npm page for more details!"
              githubLink="https://github.com/Ziw1/medal.db"
              npmLink="https://www.npmjs.com/package/medal.db"
              image={dbDbSvg} 
              icon="database" 
            />
          </div>
        </motion.div>
        <motion.div
          className="text-center mt-12"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <p className="text-gray-800 mb-4 text-xl font-semibold">
            Inspired by our projects? Have an idea or a unique project in mind? Let's bring it to life together! Click below to discuss your project with us.
          </p>
          <button
            onClick={handleRequestProjectClick}
            className="bg-primary text-white py-2 px-6 rounded hover:bg-primary-dark transition-colors"
          >
            Request a Project
          </button>
        </motion.div>
      </div>  
    </section>
  );
};

export default Works;
