import React, { useState } from 'react';
import { motion } from 'framer-motion';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } },
};

const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <motion.div
    className="border border-gray-300 rounded-lg mb-4 overflow-hidden"
    initial="hidden"
    animate="visible"
    variants={fadeInUp}
  >
    <button
      className={`w-full text-left px-6 py-4 bg-primary text-white font-semibold transition-colors duration-300 ease-in-out focus:outline-none ${isOpen ? 'bg-secondary' : 'bg-primary'}`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        {title}
        <span className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </div>
    </button>
    <motion.div
      className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isOpen ? 'max-h-96' : 'max-h-0'}`}
      initial="hidden"
      animate={isOpen ? "visible" : "hidden"}
      variants={fadeIn}
    >
      <div className="px-6 py-4 bg-gray-50 text-gray-700">
        {content}
      </div>
    </motion.div>
  </motion.div>
);

const PrivacyPolicyAccordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-primary mb-8 text-center">Privacy Policy</h1>
      <div className="prose lg:prose-xl mx-auto">
        <AccordionItem
          title="Introduction"
          content={
            <p>
              Welcome to our Privacy Policy page! When you use our website, you trust us with your information. We are committed to protecting your privacy and ensuring that your data is handled securely.
            </p>
          }
          isOpen={openIndex === 0}
          onClick={() => handleToggle(0)}
        />
        <AccordionItem
          title="Information We Collect"
          content={
            <p>
              We may collect personal information such as your name, email address, and other contact details when you register or interact with our website.
            </p>
          }
          isOpen={openIndex === 1}
          onClick={() => handleToggle(1)}
        />
        <AccordionItem
          title="How We Use Your Information"
          content={
            <p>
              The information we collect is used to improve your experience on our website, provide customer support, and communicate with you about updates and promotions.
            </p>
          }
          isOpen={openIndex === 2}
          onClick={() => handleToggle(2)}
        />
        <AccordionItem
          title="Data Security"
          content={
            <p>
              We implement various security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
            </p>
          }
          isOpen={openIndex === 3}
          onClick={() => handleToggle(3)}
        />
        <AccordionItem
          title="Your Rights"
          content={
            <p>
              You have the right to access, correct, or delete your personal information. If you have any concerns about your data, please contact us.
            </p>
          }
          isOpen={openIndex === 4}
          onClick={() => handleToggle(4)}
        />
        <AccordionItem
          title="Changes to This Policy"
          content={
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
            </p>
          }
          isOpen={openIndex === 5}
          onClick={() => handleToggle(5)}
        />
        <AccordionItem
          title="Contact Us"
          content={
            <p>
              If you have any questions about this Privacy Policy, please contact us at [Your Contact Email].
            </p>
          }
          isOpen={openIndex === 6}
          onClick={() => handleToggle(6)}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicyAccordion;
