import React from 'react';
import { motion } from 'framer-motion';
import Card from '../components/Card'; 
import { Link } from 'react-router-dom'; 
import TeamMemberCard from '../components/TeamMemberCard';
import info from '../assets/images/info.svg'; 
import discordBotSvg from '../assets/images/maxbot.gif'; 
import dbDbSvg from '../assets/images/database.gif';

const Home = () => {
  
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  const imageAnimation = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 1, ease: 'easeInOut' } },
  };

  return (
    <motion.div
      className="bg-background text-text min-h-screen py-10 md:py-20"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <div className="container mx-auto px-4 md:px-6 lg:px-8">
        <motion.section className="text-center mb-12 md:mb-16" variants={fadeInUp}>
          <h1 className="text-3xl md:text-5xl font-extrabold mb-4 md:mb-6 text-primary">Welcome to Medal Team</h1>
          <p className="text-base md:text-lg text-text">We are a team of professionals dedicated to delivering excellence in every project.</p>
        </motion.section>

        <motion.section className="flex flex-col lg:flex-row lg:justify-between items-center mb-16 md:mb-20" variants={fadeInUp}>
          <div className="w-full lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0 px-4 lg:px-0">
            <motion.h2 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6 text-primary">Who We Are</motion.h2>
            <motion.p className="text-base md:text-lg text-text">Our team consists of experts in various fields, committed to achieving the best results for our clients.</motion.p>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
            <motion.img
              src={info}
              alt="Team SVG"
              className="w-3/4 md:w-2/3 lg:w-1/2"
              variants={imageAnimation}
            />
          </div>
        </motion.section>

        <motion.section className="mb-12 md:mb-16" variants={fadeInUp}>
          <h2 className="text-2xl md:text-4xl font-bold text-center mb-6 md:mb-8 text-primary">Our Projects</h2>
          <div className="flex flex-col md:flex-row flex-wrap justify-center gap-6 md:gap-8">
            <div className="w-full md:w-1/2 lg:w-1/3 mb-4">
              <Card
                title="MaxBot"
                author="MedalTeam"
                description="Welcome to the official website repository for our Discord bot. This website serves as a hub of information, updates, and support for our Discord community. Engage with us and explore the functionalities of MaxBot!"
                discordLink="https://discord.gg/your-discord-link" 
                websiteLink="https://max-bot.xyz/"
                image={discordBotSvg}
                icon="discord" 
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mb-4">
              <Card
                title="medal.db"
                author="MedalTeam"
                description="A simple and easy database package to make your life easier. Latest version: 1.0.0. Integrate medal.db into your projects with ease. Check out the npm page for more details!"
                githubLink="https://github.com/Ziw1/medal.db"
                npmLink="https://www.npmjs.com/package/medal.db"
                image={dbDbSvg}
                icon="database" 
              />
            </div>
          </div>
          <div className="text-center mt-8">
            <Link to="/works" className="bg-primary text-white px-6 py-3 rounded-lg font-bold text-lg">
              View All Projects
            </Link>
          </div>
        </motion.section>

        <motion.section variants={fadeInUp}>
          <h2 className="text-2xl md:text-4xl font-bold text-center mb-6 md:mb-8 text-primary">Meet the Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            <TeamMemberCard name="Virus" role="Owner & Developer" image="https://cdn.discordapp.com/avatars/301340095653347329/522ca1e77663b99e20b38ed2b81f0aa7.png?size=1024&format=webp&quality=lossless&width=0&height=256" />
            <TeamMemberCard name="Winget" role="Owner & Developer" image="https://cdn.discordapp.com/avatars/967380255666995260/9ae89d42897c8f70a8570c1bdd1a754b.png?size=1024&format=webp&quality=lossless&width=0&height=256" />
            <TeamMemberCard name="M7Md" role="Owner & Developer" image="https://images-ext-1.discordapp.net/external/qYWe1XIrQGxhkTvAKCFwQKbXXVecBqQuwDu9QAKDwAk/%3Fsize%3D1024/https/cdn.discordapp.com/avatars/1091118468155314306/91997c323ec129b1a88eff7293c17771.webp?format=webp" />
          </div>
          <div className="text-center mt-8">
            <Link to="/team" className="bg-primary text-white px-6 py-3 rounded-lg font-bold text-lg">
              View All Members
            </Link>
          </div>
        </motion.section>
      </div>
    </motion.div>
  );
};

export default Home;
