import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons'; 
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const Card = ({ title, author, description, githubLink, websiteLink, npmLink, discordLink, image }) => {
    const redirectToLink = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className="bg-secondary shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 p-6 flex flex-col h-full max-w-sm mx-auto">
            <div className="w-24 h-24 mb-4 relative overflow-hidden rounded-full mx-auto">
                <img
                    src={image}
                    alt={title}
                    className="w-full h-full object-cover rounded-full"
                />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-primary">{title}</h3>
            <p className="text-text mb-3">
                <i className="fas fa-check-circle text-green-500 mr-2"></i> By <span className="text-accent">{author}</span>
            </p>
            <p className="text-text mb-4">{description}</p>
            <div className="mt-auto flex flex-wrap gap-2">
                {discordLink && (
                    <button
                        onClick={() => redirectToLink(discordLink)}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-400 transition-colors"
                    >
                        <FontAwesomeIcon icon={faDiscord} className="mr-2" /> Discord
                    </button>
                )}
                {githubLink && (
                    <button
                        onClick={() => redirectToLink(githubLink)}
                        className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors"
                    >
                        <FontAwesomeIcon icon={faGlobe} className="mr-2" /> Code
                    </button>
                )}
                {websiteLink && (
                    <button
                        onClick={() => redirectToLink(websiteLink)}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-400 transition-colors"
                    >
                        <FontAwesomeIcon icon={faGlobe} className="mr-2" /> Website
                    </button>
                )}
                {npmLink && (
                    <button
                        onClick={() => redirectToLink(npmLink)}
                        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-400 transition-colors"
                    >
                        <FontAwesomeIcon icon={faCube} className="mr-2" /> npm
                    </button>
                )}
            </div>
        </div>
    );
};

export default Card;
