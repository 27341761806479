import React, { useState } from 'react';
import { motion } from 'framer-motion';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } },
};

const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <motion.div
    className="border border-gray-300 rounded-lg mb-4 overflow-hidden"
    initial="hidden"
    animate="visible"
    variants={fadeInUp}
  >
    <button
      className={`w-full text-left px-6 py-4 bg-primary text-white font-semibold transition-colors duration-300 ease-in-out focus:outline-none ${isOpen ? 'bg-secondary' : 'bg-primary'}`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        {title}
        <span className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </div>
    </button>
    <motion.div
      className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isOpen ? 'max-h-96' : 'max-h-0'}`}
      initial="hidden"
      animate={isOpen ? "visible" : "hidden"}
      variants={fadeIn}
    >
      <div className="px-6 py-4 bg-gray-50 text-gray-700">
        {content}
      </div>
    </motion.div>
  </motion.div>
);

const TermsOfServiceAccordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-primary mb-8 text-center">Terms of Service</h1>
      <div className="prose lg:prose-xl mx-auto">
        <AccordionItem
          title="Introduction"
          content={
            <p>
              These Terms of Service govern your use of our website. By accessing or using our website, you agree to comply with and be bound by these terms.
            </p>
          }
          isOpen={openIndex === 0}
          onClick={() => handleToggle(0)}
        />
        <AccordionItem
          title="Acceptance of Terms"
          content={
            <p>
              By using our website, you agree to these Terms of Service. If you do not agree with these terms, please do not use our website.
            </p>
          }
          isOpen={openIndex === 1}
          onClick={() => handleToggle(1)}
        />
        <AccordionItem
          title="User Responsibilities"
          content={
            <p>
              You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            </p>
          }
          isOpen={openIndex === 2}
          onClick={() => handleToggle(2)}
        />
        <AccordionItem
          title="Content Ownership"
          content={
            <p>
              All content on our website, including text, images, and logos, is owned by us or our licensors and may not be used without permission.
            </p>
          }
          isOpen={openIndex === 3}
          onClick={() => handleToggle(3)}
        />
        <AccordionItem
          title="Limitation of Liability"
          content={
            <p>
              We are not liable for any damages resulting from your use of our website or any content provided on our website.
            </p>
          }
          isOpen={openIndex === 4}
          onClick={() => handleToggle(4)}
        />
        <AccordionItem
          title="Changes to Terms"
          content={
            <p>
              We may update these Terms of Service from time to time. We will notify you of any changes by posting the new terms on this page.
            </p>
          }
          isOpen={openIndex === 5}
          onClick={() => handleToggle(5)}
        />
        <AccordionItem
          title="Contact Us"
          content={
            <p>
              If you have any questions about these Terms of Service, please contact us at [Your Contact Email].
            </p>
          }
          isOpen={openIndex === 6}
          onClick={() => handleToggle(6)}
        />
      </div>
    </div>
  );
};

export default TermsOfServiceAccordion;
