import React from 'react';
import { motion } from 'framer-motion'; 
import ideaSvg from '../assets/images/about.svg'; 

const About = () => {
  // Define animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  return (
    <div className="container mx-auto px-4 py-12 mt-16">
      <motion.h1
        className="text-5xl font-bold text-center mb-8"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        About Us
      </motion.h1>
      <div className="flex flex-col lg:flex-row items-center mb-12">
        <motion.div
          className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <p className="text-gray-700 text-center lg:text-left text-lg lg:text-xl mb-6">
            Medal Team is composed of talented professionals with expertise in development, design, and project management.
            Our mission is to deliver high-quality solutions that exceed client expectations.
          </p>
          <ul className="list-disc list-inside text-gray-700 text-center lg:text-left text-lg lg:text-xl mb-6">
            <li>Experienced in cutting-edge technologies.</li>
            <li>Committed to delivering on time and within budget.</li>
            <li>Passionate about innovation and creativity.</li>
          </ul>
        </motion.div>
        <motion.div
          className="lg:w-1/2"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <img
            src={ideaSvg}
            alt="Idea Concept"
            className="w-4/5 h-auto"
          />
        </motion.div>
      </div>
      
      <motion.section
        className="text-center mb-12"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <h2 className="text-3xl font-bold mb-6">What Our Clients Say</h2>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg inline-block max-w-xl">
          <p className="text-lg">
            "The Medal Team provided exceptional service and delivered a product that exceeded our expectations. Their attention to detail and dedication were evident throughout the project."
          </p>
          <p className="mt-4 font-semibold">- Rio</p>
        </div>
      </motion.section>
      
      <motion.section
        className="text-center"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <h2 className="text-3xl font-bold mb-6">Our Goals</h2>
        <p className="text-gray-700 text-lg max-w-2xl mx-auto">
          Our goal is to continually improve our processes and adopt new technologies to stay at the forefront of our industry. We strive to foster a collaborative environment where innovation thrives, and each team member can contribute their unique skills and perspectives.
        </p>
      </motion.section>
    </div>
  );
};

export default About;
