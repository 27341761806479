import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import logo from '../assets/images/logo.png';

const LoadingPage = ({ onFinish }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
      setTimeout(() => {
        onFinish();
      }, 1000);
    }, 2000);

    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <div className="loading-container flex justify-center items-center h-screen bg-background">
      {!showContent ? (
        <motion.div
          className="logo-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, rotate: 360 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        >
          <motion.img
            src={logo}
            alt="Team Logo"
            className="logo w-40 h-40"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 1.5,
              ease: 'easeInOut',
            }}
          />
        </motion.div>
      ) : (
        <motion.div
          className="shattered-effect"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        >
        
        </motion.div>
      )}
    </div>
  );
};

export default LoadingPage;
